
import { useRoute } from "vue-router"

export default {
  props: ["equipmentId"],
  setup() {
    const router = useRoute()
    window.addEventListener("popstate", () => {
      try {
        window.wx.miniProgram.navigateBack()
      } catch (e) {
        console.error(e)
      }
    })
    return {
      temp: router.query.temp as string
    }
  }
}
